import {
  deleteUndefinedFields,
  isNilOrEmpty,
  RequiredAny,
  SessionShoes,
  validateOptionalNumber,
  validateOptionalString,
  validateRequiredArray,
  validateRequiredNumber,
  validateRequiredObject,
  Visit,
  visitModel,
} from '@atogear/arion-utils';

import { TimeFrameId, VisitAverages, VisitTotals } from '../types/stats';

import { validateTimeFrameId } from '../utils/apiUtils';

export interface VisitDemographics {
  age: {
    age: number;
    '0to20': number;
    '20to25': number;
    '25to30': number;
    '30to35': number;
    '35to40': number;
    '40to50': number;
    '50to100': number;
    unknown: number;
  };
  gender: {
    male: number;
    female: number;
    other: number;
    unknown: number;
  };
}

export interface VisitInjuries {
  lowerBack: number;
  leftUpperLeg: number;
  rightUpperLeg: number;
  leftKnee: number;
  rightKnee: number;
  leftLowerLeg: number;
  rightLowerLeg: number;
  leftAnkle: number;
  rightAnkle: number;
  leftAchilles: number;
  rightAchilles: number;
  leftFoot: number;
  rightFoot: number;
  unknown: number;
}

export interface ApiVisit extends Omit<Visit, 'injuries'> {
  // Extra info added in api
  numberOfSessions: number;
  demographics: VisitDemographics;
  injuries: VisitInjuries;
}

export interface VisitData {
  _id: TimeFrameId;
  avg: VisitAverages;
  totals: VisitTotals;
  visits: ApiVisit[];
}

// Utils
export const hasHealthData = (visit: ApiVisit) => {
  const fields = ['age', 'gender', 'injuries', 'height', 'weigth'];

  return fields.some((fn) => !isNilOrEmpty(visit[fn as keyof ApiVisit]));
};

export const isShared = (visit: ApiVisit) => !!(visit.sharedAt ?? visit.email);

export const getSoldShoes = (visit: ApiVisit) => {
  const sessions = visit.sessions;
  const soldShoes: SessionShoes = {};
  Object.keys(sessions).forEach((key) => {
    if (sessions[key].isPurchased) {
      soldShoes[key] = sessions[key];
    }
  });
  return soldShoes;
};

export const getNumberOfSoldShoes = (visit: ApiVisit) => {
  if (visit.boughtShoes && visit.boughtShoes[0]) {
    return (
      visit.boughtShoes.filter(({ brand }) => brand !== 'none').length ?? 0
    );
  }

  const sessions = getSoldShoes(visit);

  return Object.keys(sessions).length;
};

// Validation
const validateDemographics = (data: RequiredAny): VisitDemographics => {
  validateRequiredObject(data, 'demographics');

  const { age, gender } = data;

  validateRequiredObject(age, 'age');
  validateRequiredObject(gender, 'gender');

  return {
    age: {
      age: validateRequiredNumber(age.age, 'age'),
      '0to20': validateRequiredNumber(age['0to20'], '0to20'),
      '20to25': validateRequiredNumber(age['20to25'], '20to25'),
      '25to30': validateRequiredNumber(age['25to30'], '25to30'),
      '30to35': validateRequiredNumber(age['30to35'], '30to35'),
      '35to40': validateRequiredNumber(age['35to40'], '35to40'),
      '40to50': validateRequiredNumber(age['40to50'], '40to50'),
      '50to100': validateRequiredNumber(age['50to100'], '50to100'),
      unknown: validateRequiredNumber(age.unknown, 'unknown'),
    },
    gender: {
      female: validateRequiredNumber(gender.female, 'female'),
      male: validateRequiredNumber(gender.male, 'male'),
      other: validateRequiredNumber(gender.other, 'other'),
      unknown: validateRequiredNumber(gender.unknown, 'unknown'),
    },
  };
};

const validateInjuries = (data: RequiredAny): VisitInjuries => {
  validateRequiredObject(data, 'injuries');

  return {
    lowerBack: validateRequiredNumber(data.lowerBack, 'lowerBack'),
    leftUpperLeg: validateRequiredNumber(data.leftUpperLeg, 'leftUpperLeg'),
    rightUpperLeg: validateRequiredNumber(data.rightUpperLeg, 'rightUpperLeg'),
    leftKnee: validateRequiredNumber(data.leftKnee, 'leftKnee'),
    rightKnee: validateRequiredNumber(data.rightKnee, 'rightKnee'),
    leftLowerLeg: validateRequiredNumber(data.leftLowerLeg, 'leftLowerLeg'),
    rightLowerLeg: validateRequiredNumber(data.rightLowerLeg, 'rightLowerLeg'),
    leftAnkle: validateRequiredNumber(data.leftAnkle, 'leftAnkle'),
    rightAnkle: validateRequiredNumber(data.rightAnkle, 'rightAnkle'),
    leftAchilles: validateRequiredNumber(data.leftAchilles, 'leftAchilles'),
    rightAchilles: validateRequiredNumber(data.rightAchilles, 'rightAchilles'),
    leftFoot: validateRequiredNumber(data.leftFoot, 'leftFoot'),
    rightFoot: validateRequiredNumber(data.rightFoot, 'rightFoot'),
    unknown: validateRequiredNumber(data.unknown, 'unknown'),
  };
};

const validateVisit = (data: RequiredAny): ApiVisit => {
  validateRequiredObject(data, 'visit data');

  const { injuries, numberOfSessions, demographics, ...rest } = data;

  return deleteUndefinedFields({
    ...visitModel.serialize(rest),
    // Extra info added in api
    numberOfSessions: validateRequiredNumber(
      numberOfSessions,
      'numberOfSessions',
    ),
    demographics: validateDemographics(demographics),
    injuries: validateInjuries(injuries),
  });
};

export const serialize = (data: RequiredAny): VisitData => {
  validateRequiredObject(data, 'api visit data');

  return {
    _id: validateTimeFrameId(data._id),
    avg: {
      age: validateOptionalNumber(data.avg.age, 'avg.age'),
      gender: validateOptionalString(data.avg.gender, 'avg.gender'),
      shoesSold: validateOptionalNumber(data.avg.shoesSold, 'avg.shoesSold'),
      shoesTried: validateOptionalNumber(data.avg.shoesTried, 'avg.shoesTried'),
    },
    totals: {
      shoesSold: validateRequiredNumber(
        data.totals.shoesSold,
        'totals.shoesSold',
      ),
      shoesTried: validateRequiredNumber(
        data.totals.shoesTried,
        'totals.shoesTried',
      ),
      visits: validateRequiredNumber(data.totals.visits, 'totals.visits'),
      visitsShared: validateRequiredNumber(
        data.totals.visitsShared,
        'totals.visitsShared',
      ),
    },
    visits: validateRequiredArray(data.visits, 'visits').map(validateVisit),
  };
};
