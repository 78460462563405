import {
  BillingCycles,
  deleteUndefinedFields,
  formatDate,
  formatDateTime,
  formatFullName,
  formatInitials,
  formatPercentage,
  formatPrice,
  formatUrl,
  isNilOrWhitespace,
  RequiredAny,
  StoreModule,
  StoreModules,
  StoreModulesSchema,
  toDate,
  toMillis,
  trimWhiteSpace,
  User,
} from '@atogear/arion-utils';

import { userModel } from '@atogear/arion-utils';

import { addDays, differenceInCalendarMonths } from 'date-fns';

import { GroupType, Product, ProspectStatus } from '../enums';
import countries from '../assets/data/countries.json';
import { z } from 'zod';

const ProspectSchema = z.object({
  // Prospect Info
  prospectId: z.string(),
  name: z.string(),
  storeCount: z.number(),
  createdAt: z.unknown().transform(toMillis),
  type: z.nativeEnum(GroupType),
  products: z.array(z.nativeEnum(Product)),
  priority: z.boolean(),
  prospectStatus: z.nativeEnum(ProspectStatus),
  expectedCloseDate: z.unknown().transform(toMillis).nullish(),
  notes: z.string().optional(),
  preferredLanguage: z.string(),
  // Address
  address: z.string().optional(),
  zipCode: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
  // Contact Info
  email: z.string().toLowerCase().optional(),
  website: z.string().toLowerCase().optional(),
  callingCode: z.string().optional(),
  phoneNumber: z.string().optional(),
  // Modules
  activeModules: StoreModulesSchema,
  // Subscription Info
  subscriptionPeriod: z.number().optional(),
  subscriptionStartDate: z.unknown().transform(toMillis).optional(),
  subscriptionEndDate: z.unknown().transform(toMillis).optional(),
  subscriptionCreatedAt: z.unknown().transform(toMillis).optional(),
  subscriptionPrice: z.number().optional(),
  subscriptionCurrency: z.string().optional(),
  isDistributorSubscription: z.boolean().optional(),
  // Quote
  quotePath: z.string().optional(),
  hardwareSetQuantity: z.number().optional(),
  extras: z.string().optional(),
  // Agreement
  agreementPath: z.string().optional(),
  // Billing Info
  billingFirstName: z.string().optional(),
  billingLastName: z.string().optional(),
  billingEmail: z.string().toLowerCase().optional(),
  billingCycle: z.nativeEnum(BillingCycles).optional(),
  billingDiscount: z.number().optional(),
  billingPaymentTerm: z.number().optional(),
  // Owner
  ownerId: z.string().optional(),
  // Sales Rep
  salesId: z.string().optional(),
  salesFirstName: z.string().optional(),
  salesLastName: z.string().optional(),
  salesEmail: z.string().toLowerCase().optional(),
  salesPhoneNumber: z.string().optional(),
});

export interface Prospect extends z.infer<typeof ProspectSchema> {}

export const ExternalProspectSchema = ProspectSchema.extend({
  createdAt: z.number().transform(toDate),
  expectedCloseDate: z.number().transform(toDate).nullable(),
  subscriptionStartDate: z.number().transform(toDate),
  subscriptionEndDate: z.number().transform(toDate),
  subscriptionCreatedAt: z.number().transform(toDate),
}).partial();

export interface ExternalProspect
  extends z.infer<typeof ExternalProspectSchema> {}

export type CreateProspectData = Pick<
  Prospect,
  // Prospect Info
  | 'name'
  | 'type'
  | 'products'
  | 'priority'
  | 'notes'
  | 'preferredLanguage'
  // Address
  | 'address'
  | 'zipCode'
  | 'city'
  | 'country'
  // Contact Info
  | 'email'
  | 'website'
  | 'callingCode'
  | 'phoneNumber'
>;

const PRODUCT_ORDER: Record<Product, number> = {
  hub: 0,
  eHub: 1,
  pro: 2,
  studio: 3,
  other: 4,
};

// Fields
export const fields = {
  // Prospect Info
  ID: 'prospectId',
  NAME: 'name',
  STORE_COUNT: 'storeCount',
  CREATED_AT: 'createdAt',
  TYPE: 'type',
  PRODUCTS: 'products',
  PRIORITY: 'priority',
  PROSPECT_STATUS: 'prospectStatus',
  EXPECTED_CLOSE_DATE: 'expectedCloseDate',
  NOTES: 'notes',
  PREFERRED_LANGUAGE: 'preferredLanguage',
  // Address
  ADDRESS: 'address',
  ZIP_CODE: 'zipCode',
  CITY: 'city',
  COUNTRY: 'country',
  // Contact Info
  EMAIL: 'email',
  WEBSITE: 'website',
  CALLING_CODE: 'callingCode',
  PHONE_NUMBER: 'phoneNumber',
  // Modules
  MODULES: 'activeModules',
  COMPARE: 'comparisonModule',
  ADVISOR: 'customAdvisor',
  THEME: 'customTheme',
  VIDEO: 'videoModule',
  WALKING: 'walkingMode',
  INSOLE_RECOMMENDATION: 'insoleRecommendation',
  SHOE_RECOMMENDATION: 'shoeRecommendation',
  ACCELERATED_ONBOARDING: 'acceleratedOnboarding',
  SHOE_MANAGEMENT: 'shoeManagement',
  // Subscription Info
  SUBSCRIPTION_PERIOD: 'subscriptionPeriod', // in years
  SUBSCRIPTION_START_DATE: 'subscriptionStartDate',
  SUBSCRIPTION_END_DATE: 'subscriptionEndDate',
  SUBSCRIPTION_CREATED_AT: 'subscriptionCreatedAt',
  SUBSCRIPTION_PRICE: 'subscriptionPrice',
  SUBSCRIPTION_CURRENCY: 'subscriptionCurrency',
  IS_DISTRIBUTOR_SUBSCRIPTION: 'isDistributorSubscription',
  // Quote
  QUOTE_PATH: 'quotePath',
  HARDWARE_SET_QUANTITY: 'hardwareSetQuantity',
  EXTRAS: 'extras',
  // Agreement
  AGREEMENT_PATH: 'agreementPath',
  // Billing Info
  BILLING_FIRST_NAME: 'billingFirstName',
  BILLING_LAST_NAME: 'billingLastName',
  BILLING_EMAIL: 'billingEmail',
  BILLING_CYCLE: 'billingCycle',
  BILLING_DISCOUNT: 'billingDiscount',
  BILLING_PAYMENT_TERM: 'billingPaymentTerm',
  // Owner
  OWNER_ID: 'ownerId',
  // Sales Rep
  SALES_ID: 'salesId',
  SALES_FIRST_NAME: 'salesFirstName',
  SALES_LAST_NAME: 'salesLastName',
  SALES_EMAIL: 'salesEmail',
  SALES_PHONE_NUMBER: 'salesPhoneNumber',
};

// Defaults
export const defaults: Prospect = {
  // Prospect Info
  name: '',
  prospectId: '',
  storeCount: 0,
  createdAt: Date.now(),
  priority: false,
  type: GroupType.BRAND,
  products: [Product.HUB],
  prospectStatus: ProspectStatus.IN_PREPARATION,
  preferredLanguage: 'english',
  // Modules
  activeModules: {
    acceleratedOnboarding: false,
    comparisonModule: true,
    customAdvisor: false,
    customTheme: false,
    insoleRecommendation: true,
    shoeManagement: false,
    shoeRecommendation: true,
    videoModule: true,
    walkingMode: false,
    fusionInsoles: false,
    podInsoles: true,
  },
};

// Dummy
const dummyProspect: Prospect = {
  // Prospect Info
  prospectId: 'dummy-group-prospect-id',
  name: 'Dummy Prospect',
  storeCount: 0,
  createdAt: Date.now(),
  priority: false,
  type: GroupType.CHAIN,
  products: [Product.HUB],
  prospectStatus: ProspectStatus.CONTACTED,
  expectedCloseDate: Date.now(),
  preferredLanguage: 'english',
  // Modules
  activeModules: {
    acceleratedOnboarding: false,
    comparisonModule: true,
    customAdvisor: false,
    customTheme: false,
    insoleRecommendation: true,
    shoeManagement: false,
    shoeRecommendation: true,
    videoModule: true,
    walkingMode: false,
    fusionInsoles: false,
    podInsoles: true,
  },
};

// Group Prospect Info
export const getId = (prospect: Prospect) => prospect.prospectId;

// Info
export const getName = (prospect: Prospect) => prospect.name;

export const setName = (name: string, prospect: Prospect): Prospect => ({
  ...prospect,
  name,
});

export const getStoreCount = (prospect: Prospect) => prospect.storeCount;

export const hasStores = (prospect: Prospect) => getStoreCount(prospect) > 0;

export const getCreatedAt = (prospect: Prospect) => prospect.createdAt;

export const getFormattedCreatedAt = (prospect: Prospect) =>
  formatDate(getCreatedAt(prospect));

export const hasPriority = (prospect: Prospect) => prospect.priority;

export const getType = (prospect: Prospect) => prospect.type;

export const getProducts = (prospect: Prospect) => prospect.products;

export const toggleProduct = (product: Product, products: Product[]) => {
  const newProducts = [...products];

  const productIndex = newProducts.findIndex((p) => p === product);

  if (productIndex === -1) {
    newProducts.push(product);
  } else {
    newProducts.splice(productIndex, 1);
  }

  return newProducts.sort((a, b) => PRODUCT_ORDER[a] - PRODUCT_ORDER[b]);
};

export const getStatus = (prospect: Prospect) => prospect.prospectStatus;

export const getExpectedCloseDate = (prospect: Prospect) =>
  prospect.expectedCloseDate;

export const getFormattedExpectedCloseDate = (prospect: Prospect) =>
  formatDate(getExpectedCloseDate(prospect));

export const getNotes = (prospect: Prospect) => prospect.notes;

export const hasNotes = (prospect: Prospect) =>
  !isNilOrWhitespace(getNotes(prospect));

export const getPreferredLanguage = (prospect: Prospect) =>
  prospect.preferredLanguage;

// Address
export const getStreet = (prospect: Prospect) => prospect.address;

export const getZipCode = (prospect: Prospect) => prospect.zipCode;

export const getCity = (prospect: Prospect) => prospect.city;

export const getCountryCode = (prospect: Prospect) => prospect.country;

export const getCountryName = (prospect: Prospect) => {
  const code = getCountryCode(prospect);

  const result = countries.find(({ alpha2 }) => alpha2 === code);

  return result?.name || code;
};

export const getAddress = (prospect: Prospect) => {
  const values = [
    getStreet(prospect),
    getZipCode(prospect),
    getCity(prospect),
    getCountryName(prospect),
  ].filter((x) => !isNilOrWhitespace(x));

  return values.length > 0 ? values.join(', ') : 'Not set';
};

// Contact Info
export const getEmail = (prospect: Prospect) => prospect.email?.toLowerCase();

export const getWebsite = (prospect: Prospect) =>
  prospect.website?.toLowerCase();

export const getFormattedWebsite = (prospect: Prospect) =>
  formatUrl(getWebsite(prospect));

export const getCallingCode = (prospect: Prospect) => prospect.callingCode;

export const getFormattedCallingCode = (prospect: Prospect) =>
  countries.find(({ alpha2 }) => alpha2 === getCallingCode(prospect))
    ?.callingCode || '';

export const getPhoneNumber = (prospect: Prospect) => prospect.phoneNumber;

export const getFormattedPhoneNumber = (prospect: Prospect) =>
  trimWhiteSpace(
    `${getFormattedCallingCode(prospect)} ${getPhoneNumber(prospect) || ''}`,
  );

// Modules
export const getModules = (prospect: Prospect): StoreModules =>
  prospect.activeModules;

export const setModules = (
  activeModules: StoreModules,
  prospect: Prospect,
): Prospect => ({
  ...prospect,
  activeModules: {
    ...prospect.activeModules,
    ...activeModules,
  },
});

export const toggleModule = (
  key: StoreModule,
  prospect: Prospect,
): Prospect => ({
  ...prospect,
  activeModules: {
    ...prospect.activeModules,
    [key]: !prospect.activeModules[key],
  },
});

export const hasComparison = (prospect: Prospect) =>
  getModules(prospect).comparisonModule;

export const hasCustomAdvisor = (prospect: Prospect) =>
  getModules(prospect).customAdvisor;

export const hasCustomTheme = (prospect: Prospect) =>
  getModules(prospect).customTheme;

export const hasInsoleRecommendation = (prospect: Prospect) =>
  getModules(prospect).insoleRecommendation;

export const hasShoeRecommendation = (prospect: Prospect) =>
  getModules(prospect).shoeRecommendation;

export const hasAcceleratedOnboarding = (prospect: Prospect) =>
  getModules(prospect).acceleratedOnboarding;

export const hasShoeManagement = (prospect: Prospect) =>
  getModules(prospect).shoeManagement;

export const hasVideo = (prospect: Prospect) =>
  getModules(prospect).videoModule;

export const hasWalkingMode = (prospect: Prospect) =>
  getModules(prospect).walkingMode;

// Subscription Info
export const getSubscriptionPeriod = (prospect: Prospect) =>
  prospect.subscriptionPeriod;

export const getSubscriptionStartDate = (prospect: Prospect) =>
  prospect.subscriptionStartDate;

export const getFormattedSubscriptionStartDate = (prospect: Prospect) =>
  formatDate(getSubscriptionStartDate(prospect));

export const getSubscriptionEndDate = (prospect: Prospect) =>
  prospect.subscriptionEndDate;

export const getFormattedSubscriptionEndDate = (prospect: Prospect) =>
  formatDate(getSubscriptionEndDate(prospect));

export const getDuration = (prospect: Prospect) => {
  const subStartDate = getSubscriptionStartDate(prospect);
  const subEndDate = getSubscriptionEndDate(prospect);

  if (!subStartDate) {
    return 0;
  }

  if (!subEndDate) {
    return 0;
  }

  return differenceInCalendarMonths(subEndDate, subStartDate);
};

export const getSubscriptionCreatedAt = (prospect: Prospect) =>
  prospect.subscriptionCreatedAt;

export const getSubscriptionCurrency = (prospect: Prospect) =>
  prospect.subscriptionCurrency;

export const getFormattedSubscriptionCreatedAt = (prospect: Prospect) =>
  formatDateTime(getSubscriptionCreatedAt(prospect));

export const hasSubscriptionInfo = (prospect: Prospect) =>
  !!getSubscriptionCreatedAt(prospect);

export const getMonthlySubscriptionPrice = (prospect: Prospect) =>
  prospect.subscriptionPrice ? prospect.subscriptionPrice : 0;

export const isDistributorSubscription = (prospect: Prospect) =>
  prospect.isDistributorSubscription || false;

// Quote
export const getQuotePath = (prospect: Prospect) => prospect.quotePath;

export const getHardwareSetQuantity = (prospect: Prospect) =>
  prospect.hardwareSetQuantity;

export const getFormattedHardwareSetQuantity = (prospect: Prospect) => {
  const qunatity = getHardwareSetQuantity(prospect);
  return qunatity ? qunatity.toString() : undefined;
};

export const getExtras = (prospect: Prospect) => prospect.extras;

// Agreement
export const getAgreementPath = (prospect: Prospect) => prospect.agreementPath;

// Billing Info
export const getBillingFirstName = (prospect: Prospect) =>
  prospect.billingFirstName;

export const getBillingLastName = (prospect: Prospect) =>
  prospect.billingLastName;

export const getBillingName = (prospect: Prospect) =>
  formatFullName(getBillingFirstName(prospect), getBillingLastName(prospect));

export const getBillingEmail = (prospect: Prospect) =>
  prospect.billingEmail?.toLowerCase();

export const hasBillingInfo = (prospect: Prospect) =>
  ![
    getBillingFirstName(prospect),
    getBillingLastName(prospect),
    getBillingEmail(prospect),
  ].some(isNilOrWhitespace);

export const getBillingCycle = (prospect: Prospect) => prospect.billingCycle;

export const isMonthly = (prospect: Prospect) => {
  const billingCycle = getBillingCycle(prospect);

  return !billingCycle || billingCycle === BillingCycles.MONTHLY;
};

export const getBillingDiscount = (prospect: Prospect) =>
  prospect.billingDiscount;

export const getBillingPaymentTerm = (prospect: Prospect) =>
  prospect.billingPaymentTerm;

export const getPaymentDate = (prospect: Prospect) => {
  const subStartDate = getSubscriptionStartDate(prospect);

  if (!subStartDate) {
    return undefined;
  }

  const billingPaymentTerm = getBillingPaymentTerm(prospect);

  if (!billingPaymentTerm) {
    return undefined;
  }

  return addDays(subStartDate, billingPaymentTerm).getTime();
};

export const getFormattedPaymentDate = (prospect: Prospect) =>
  formatDate(getPaymentDate(prospect));

export const calcSubscriptionPrice = (prospect: Prospect, basePrice = 400) => {
  const years = getSubscriptionPeriod(prospect) || 2;

  const discount = getBillingDiscount(prospect);

  if (!years) {
    return {
      baseTotal: 0,
      discount,
      total: 0,
      perCycle: 0,
      perMonth: 0,
      perYear: 0,
    };
  }

  const months = years * 12;

  const baseMonthly = getMonthlySubscriptionPrice(prospect) ?? basePrice;

  const baseTotal = baseMonthly * months;

  const total = discount ? baseTotal * (1 - discount) : baseTotal;
  const perMonth = total / months;

  const perYear = total / years;

  const perCycle = isMonthly(prospect) ? perMonth : perYear;

  return {
    baseTotal,
    discount,
    total,
    perCycle,
    perMonth,
    perYear,
  };
};

export const getFormattedSubscriptionPrice = (
  prospect: Prospect,
  basePrice = 400,
) => {
  const { baseTotal, discount, total, perCycle, perMonth, perYear } =
    calcSubscriptionPrice(prospect, basePrice);

  return {
    baseTotal: formatPrice(baseTotal, prospect.subscriptionCurrency),
    discount: discount ? formatPercentage(discount) : '',
    total: formatPrice(total, prospect.subscriptionCurrency),
    perCycle: formatPrice(perCycle, prospect.subscriptionCurrency),
    perMonth: formatPrice(perMonth, prospect.subscriptionCurrency),
    perYear: formatPrice(perYear, prospect.subscriptionCurrency),
  };
};

// Owner
export const getOwnerId = (prospect: Prospect) => prospect.ownerId;

export const isStoreOwner = (user: User, prospect: Prospect) =>
  userModel.isOwner(user) && userModel.getId(user) === getOwnerId(prospect);

// Sales Rep
export const getSalesId = (prospect: Prospect) => prospect.salesId;

export const getSalesRepFirstName = (prospect: Prospect) =>
  prospect.salesFirstName;

export const getSalesRepLastName = (prospect: Prospect) =>
  prospect.salesLastName;

export const getSalesRepName = (prospect: Prospect) =>
  formatFullName(getSalesRepFirstName(prospect), getSalesRepLastName(prospect));

export const getSalesRepInitials = (prospect: Prospect) =>
  formatInitials(
    getSalesRepFirstName(prospect) || '',
    getSalesRepLastName(prospect) || '',
  );

export const getSalesRepEmail = (prospect: Prospect) =>
  prospect.salesEmail?.toLowerCase();

export const getSalesRepPhoneNumber = (prospect: Prospect) =>
  prospect.salesPhoneNumber;

export const hasSalesRep = (prospect: Prospect) =>
  ![
    getSalesId(prospect),
    getSalesRepFirstName(prospect),
    getSalesRepLastName(prospect),
    getSalesRepEmail(prospect),
    // TODO: Implement getSalesRepPhoneNumber(prospect),
  ].some(isNilOrWhitespace);

export const setSalesRep = (user: User, prospect: Prospect): Prospect => ({
  ...prospect,
  salesId: userModel.getId(user),
  salesFirstName: userModel.getFirstName(user),
  salesLastName: userModel.getLastName(user),
  salesEmail: userModel.getEmail(user),
  salesPhoneNumber: '', // TODO: Implement userModel.getPhoneNumber(user),
});

export const isSalesRep = (user: User, prospect: Prospect) =>
  (userModel.isSales(user) || userModel.isDistributor(user)) &&
  getSalesId(prospect) === userModel.getId(user);

export const isInternalSalesRep = (user: User, prospect: Prospect) =>
  userModel.isInternalSales(user) && isSalesRep(user, prospect);

// Utils
export const create = (data?: Partial<Prospect>): Prospect => ({
  ...defaults,
  ...data,
  storeCount: 0,
  createdAt: Date.now(),
});

export const serialize = (data: RequiredAny): Prospect => {
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid prospect data provided');
  }

  return ProspectSchema.parse(data);
};

export const deserialize = (
  data: Partial<Prospect>,
): Partial<ExternalProspect> =>
  deleteUndefinedFields(ExternalProspectSchema.parse(data));

export const getDummy = () => dummyProspect;
