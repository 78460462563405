import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { userModel } from '@atogear/arion-utils';
import { isEmpty } from 'ramda';

import { getGlobalThemeService, getThemeService } from '../../services';

import { DefaultThemeIds } from '../../enums';

import { Theme } from '../../theme/types';
import { RootState } from '../types';
import { SelectThemesType, StoreThemesType } from './types';

interface Config {
  state: RootState;
}

// Get Themes
export const getThemes: AsyncThunk<Theme[], void, Config> = createAsyncThunk<
  Theme[],
  void,
  Config
>('themes/getThemes', async (_, { getState }): Promise<Theme[]> => {
  const user = getState().auth.user.data;
  const accessibleGroups = getState().groups.groups.data;

  if (!user) {
    throw new Error('No user signed in!');
  }

  const service = getThemeService();

  if (userModel.isPowerUser(user) || userModel.isInternalDesigner(user)) {
    const themes = await service.getAllThemes();
    return themes;
  }

  if (!accessibleGroups && !accessibleGroups[0]) return [];

  if (user.permissions?.canUpdateTheme) {
    const defaultThemes = await service.getDefaultThemes();

    const accessibleThemes = await Promise.all(
      accessibleGroups.map(async (group) => {
        const themes = await service.getGroupThemes(group.uuid);
        return [...themes];
      }),
    );

    const groupThemes = accessibleThemes.flat(1);

    return [...defaultThemes, ...groupThemes];
  }

  return [];
});

// Get Default Themes
export const getDefaultThemes = createAsyncThunk(
  'themes/getDefaultThemes',
  async (): Promise<Theme[]> => {
    const themes = await getThemeService().getDefaultThemes();

    return themes;
  },
);

// Get Any Theme
export const getTheme = createAsyncThunk(
  'themes/getTheme',
  async (themeId: string): Promise<Theme | undefined> => {
    const theme = await getThemeService().getGroupTheme(themeId);

    return theme;
  },
);

// Get Group Themes (incl. default themes)
export const getGroupThemes = createAsyncThunk(
  'themes/getGroupThemes',
  async (groupId: string) => {
    const { darkTheme, lightTheme } =
      await getGlobalThemeService().getGlobalThemes();

    const themes: Theme[] = [];

    if (darkTheme) {
      themes.push(darkTheme);
    }

    if (lightTheme) {
      themes.push(lightTheme);
    }

    if (!groupId) {
      return themes;
    }

    // Get all Themes for Group
    const groupThemes = await getThemeService().getGroupThemes(groupId);

    if (!isEmpty(groupThemes)) {
      themes.push(...groupThemes);
    }

    return themes;
  },
);

// Get Current Light and Dark Themes
export const getStoreThemes = createAsyncThunk(
  'themes/getStoreThemes',
  async ({ groupId, darkThemeId, lightThemeId }: SelectThemesType) => {
    const themes: StoreThemesType = {
      darkTheme: undefined,
      lightTheme: undefined,
    };

    if (!groupId) {
      return themes;
    }

    // Get all Themes for Group
    if (darkThemeId && darkThemeId !== DefaultThemeIds.ARION_DARK) {
      const darkTheme = await getThemeService().getTheme(groupId, darkThemeId);

      themes.darkTheme = darkTheme;
    }

    if (lightThemeId && lightThemeId !== DefaultThemeIds.ARION_LIGHT) {
      const lightTheme = await getThemeService().getTheme(
        groupId,
        lightThemeId,
      );

      themes.lightTheme = lightTheme;
    }

    if (!themes.darkTheme || !themes.lightTheme) {
      const arionThemes = await getGlobalThemeService().getGlobalThemes();

      if (!themes.darkTheme) {
        themes.darkTheme = arionThemes.darkTheme;
      }

      if (!themes.lightTheme) {
        themes.lightTheme = arionThemes.lightTheme;
      }
    }

    return themes;
  },
);

interface CreateGroupThemeParams {
  groupId: string;
  data: Theme;
}

export const createGroupTheme = createAsyncThunk(
  'themes/createGroupTheme',
  /**
   * Create Theme.
   *
   * @param params Group's id and Theme's data.
   * @returns Promise that resolves to created Theme.
   */
  async (params: CreateGroupThemeParams): Promise<Theme> => {
    const { groupId, data } = params;

    const theme = await getThemeService().createGroupTheme(groupId, data);

    return theme;
  },
);

interface CopyGroupThemeParams {
  groupId: string;
  themeId: string;
}

export const copyGroupTheme = createAsyncThunk(
  'themes/copyGroupTheme',
  /**
   * Copy Group Theme.
   *
   * @param params Group's and Theme's id.
   * @returns Promise that resolves to copied Theme.
   */
  async (params: CopyGroupThemeParams) => {
    const { groupId, themeId } = params;

    const theme = await getThemeService().copyGroupTheme(groupId, themeId);

    return theme;
  },
);

interface UpdateGroupThemeParams extends CopyGroupThemeParams {
  data: Partial<Theme>;
  newGroupId?: string;
}

export const updateGroupTheme = createAsyncThunk(
  'themes/updateGroupTheme',
  /**
   * Update Theme.
   *
   * @param params Group's id and Theme's id and data.
   * @returns Promise that resolves to updated Theme.
   */
  async (params: UpdateGroupThemeParams): Promise<Theme> => {
    const { groupId, themeId, data, newGroupId } = params;

    if (data.isDefault) {
      throw new Error('Default theme can not be updated!');
    }

    const theme = await getThemeService().updateTheme(
      groupId,
      themeId,
      data,
      newGroupId,
    );

    return theme;
  },
);

export const deleteGroupTheme = createAsyncThunk(
  'themes/deleteGroupTheme',
  /**
   * Delete Theme.
   *
   * @param params Group's id and Theme's id.
   * @returns Promise that resolves when Theme is successfully deleted.
   */
  async (params: CopyGroupThemeParams): Promise<boolean> => {
    const { groupId, themeId } = params;

    const result = await getThemeService().deleteGroupTheme(groupId, themeId);

    return result;
  },
);
